import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import TooltipContainer from "./TooltipContainer";
import {deviceIsMobile} from "../../../utils/deviceUtils";
import {isString} from "../../../utils/utils";

/**
 * Reusable Tooltip component
 */
export default class Tooltip extends React.Component {

	static defaultProps = {
		interactive: false,
		text: undefined,
		animate: true,
		element: undefined,
		viewport: undefined
	};

	static ArrowLink = props => (
		!props.href || props.href === '#'
		? null
		: <a href={props.href} className="type-arrow" target="_blank"><span>{props.children}</span></a>
	)

	constructor(props, context)
	{
		super(props, context);
		this.state = {opened: false, action: undefined};
		this.targetRef = React.createRef();
	}


	getViewPortElement()
	{
		return this.props.viewport && (
			isString(this.props.viewport)?
			document.querySelector(this.props.viewport):
			this.props.viewport.current
		);
	}


	render()
	{
		const {interactive, text, element, animate, children, ...childProps} = this.props;
		const Elem = element || (text ? 'a' : 'span');

		const callback = (e) =>
		{
			e.stopPropagation();

			if (e.type === 'click' && deviceIsMobile())
			{
				e.preventDefault();
				e.stopPropagation();
			}

			this.setState({opened: true, action: e.type});
		};

		return (<>
			<Elem
				rel="tooltip"
				ref={this.targetRef}
				onClick={callback}
				onMouseEnter={callback}
				onMouseLeave={callback}
				{...childProps}
			>
				{text}
			</Elem>
			{this.state.opened && ReactDOM.createPortal(
				<TooltipContainer
					action={this.state.action}
					viewPortElement={this.getViewPortElement()}
					interactive={interactive}
					animate={animate}
					invokerElement={this.targetRef.current}
					onClose={() => this.setState({opened: false, action:undefined})}
				>
					{children}
				</TooltipContainer>
				, document.body)}
		</>);
	}
}

Tooltip.propTypes = {
	interactive: PropTypes.bool,
	text: PropTypes.node,
	animate: PropTypes.bool,
	element: PropTypes.string,
	viewport: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({ // React reference
			current: PropTypes.object,
		}),
	]),
	htmlContent: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};
