import { deepClone } from "./objectUtils";
import { getStaticData } from './utils';
import { TypedDocumentString } from '../__generated__/graphql';

/**
 * @template TData
 * @typedef {{
 * 	data: TData | null | undefined,
 * 	errors?: Array<GraphQLError>
 * }} GraphQLResult
 *
 * @typedef {{
 * 	message: string,
 * 	path: Array<string>,
 * 	category: string,
 * 	extensions: null | {
 * 		code: string,
 * 		errors: Array<string>|null
 * 	}
 * }} GraphQLError
 */

export const config = {
	graphQlUrl: String(getStaticData()['graphQLURL'] ?? ''),
};

/**
 * @template TResult
 * @template TVariables
 * @param {TypedDocumentString<TResult, TVariables>} query
 * @param {TVariables} variables
 * @return {Promise<GraphQLResult<TResult>>}
 */
export const fetchGraphQL = async (query, variables) =>
{
	const graphQlUrl = new URL(config.graphQlUrl);
	const token = new URL(window.location.href).searchParams.get('token');
	if (token)
	{
		graphQlUrl.searchParams.set('token', token)
	}

    const res = await fetch(graphQlUrl.toString(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({
            query: query.toString(),
            variables,
        })
    });

    return await res.json();
}

/**
 *
 * @param {GraphQLResult} response
 * @param queryName
 * @returns {{}|*}
 */
export const prepareGraphQLData = (response, queryName) => {
    const {data, errors} = response;
    if (errors)
    {
        // throw celeho pole obsahujiciho vsechny chyby
        throw errors;
    }

    if (queryName in data)
    {
        return deepClone(data[queryName]);
    }
    else
    {
        return {};
    }
};

/**
 * @param {Array} errors
 * @param {string} queryName
 * @return {GraphQLError|null}
 */
export const getQueryError = (errors, queryName) => {
    return (errors && errors.find((error) => error.path && (error.path[0] === queryName))) ?? null;
};

export const repeatedFetchGraphQLWithCondition = (query, variables, timeout, onResponse, maxRepeats = 50) =>
{
    return new Promise((resolve, reject) => {
        let repeats = 0;
        const callCheck = () => {
            fetchGraphQL(query, variables)
                .then(response => onResponse(response, resolve, reject))
                .catch(() => {
                    repeats++;

                    if (repeats > maxRepeats)
                    {
                        reject('max repeats reached');
                    }
                    else
                    {
                        setTimeout(callCheck, timeout);
                    }
                })
        }
        callCheck();
    })
}

/**
 * @param {string} query
 * @param {Record<string, any>} variables
 * @param {Array<Array<string>>} filesMap e.g. [[variables.logo]] will map image no. 0 to `logo` variable in the query
 * @param {Array<File>} files
 * @return {Promise<any>}
 */
export const graphQLUpload = async (query, variables, filesMap, files) =>
{
    const formData = new FormData();

    const operations = {
        query,
        variables,
    };

    formData.append('operations', JSON.stringify(operations));

    files.forEach((file, key) => {
        formData.append(String(key), file);
    });

    formData.append('map', JSON.stringify(filesMap));

    const res = await fetch(config.graphQlUrl, {
        method: 'POST',
        body: formData,
    });

    return await res.json();
}


let cache = {};
export async function fetchGraphQLWithCache(query, variables, renew = false) {
    let key = String.fromCharCode(
        ...new Uint16Array(
            await globalThis.crypto.subtle.digest(
                "SHA-1",
                new TextEncoder().encode(JSON.stringify([query, variables]))
            )
        )
    );
    if (renew || typeof cache[key] === 'undefined')
    {
        cache[key] = fetchGraphQL(query, variables);
        cache[key] = await cache[key];
        return cache[key];
    }
    return await cache[key]; // await will handle if result is Promise or value
}
